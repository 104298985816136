<template>
    <div class="mainbox">
        <div class="detail_left detail_lefts">
            <div class="d_til">常见问题</div>
            <div @click="goDetail(item)" v-for="(item, index ) in mList" :key="index"
                :class="search.projectCategoryId == item.id ? 'd_tils d_active' : 'd_tils'">
                <div v-if="search.projectCategoryId == item.id" class="line"></div>
                {{ item.typeName }}
            </div>
        </div>
        <div class="detail_left"></div>
        <div class="detail_right">
            <div class="detail_right_til">{{ itemDetail.typeName }}常见问题</div>
            <div class="question_list">
                <div v-for="(item, index) in qusetionList" :key="index" class="question_item">
                    <div class="q_ask">
                        <div>Q</div>
                        <div>{{ item.questionName }}</div>
                    </div>
                    <div class="q_answer">
                        <div>A</div>
                        <div class="twoEllipsis"> {{ item.questionAnswer }} </div>
                    </div>
                </div>
                <el-empty v-if="!qusetionList || qusetionList.length == 0" description="暂无数据"></el-empty>


            </div>

            <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                class="pagination partner-pagination" :current-page="search.pageNum" :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
                @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]" />

        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            search: {
                pageSize: 10,
                pageNum: 1,
                projectTypeId: '',
                projectCategoryId: ''
            },
            itemDetail: {},
            total: 0,
            mList: [],
            qusetionList: [],
        };
    },
    created() {
        this.search.projectTypeId = localStorage.getItem('projectTypeId');
        this.search.projectCategoryId = localStorage.getItem('projectCategoryId');
        if (localStorage.getItem('projectCategoryId')) {

        } else {
            if (this.$route.query.id && this.$route.query.id != 'undefined' && this.$route.query.id != '') {
                this.search.projectCategoryId = this.$route.query.id
            }
        }
        know.selectProjectTypePullList({ parentId: this.search.projectTypeId }).then((res) => {
            this.mList = res.data;
        });
        this.getList()
    },
    mounted() {

    },
    methods: {
        getList() {
            know.getSchoolProblemPcList({ ...this.search }).then((res) => {
                this.qusetionList = res.rows
                this.total = res.total
            });
        },
        handleSizeChange(val) {
            this.search.pageNum = 1
            this.search.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.search.pageNum = val
            this.getList()
        },
        goDetail(item) {
            this.search.pageNum = 1
            this.itemDetail = item
            this.search.projectCategoryId = item.id
            this.getList()

        }

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .detail_left {
        width: 236px;
        border-radius: 6px 6px 6px 6px;
        background: #fff;
        padding-bottom: 20px;
        height: fit-content;

        .d_til {
            height: 43px;
            font-family: PingFangSC-Semibold, PingFangSC-Semibold;
            font-weight: normal;
            font-size: 18px;
            color: #FFFFFF;
            background: url('~@/assets/img/TechnicalCollege/9.png');
            background-size: 100% 100%;

            display: flex;
            align-items: center;
            justify-content: center;
        }


        .d_tils {
            position: relative;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 16px;
            color: #666666;
            padding-left: 40px;

            height: 37px;
            line-height: 37px;
            border-radius: 0px 0px 0px 0px;
            margin-top: 10px;
            cursor: pointer;
        }

        .d_active {
            color: #BC3535;
            background: #FEF3F3;
        }

        .line {
            position: absolute;
            width: 4px;
            height: 37px;
            background: #DF3C2F;
            border-radius: 0px 0px 0px 0px;
            left: 0;
        }

    }

    .detail_lefts {
        position: fixed;
    }

    .detail_right {
        width: 943px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;

        .detail_right_til {
            height: 70px;
            text-align: center;
            line-height: 70px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 20px;
            color: #444444;
            font-weight: bold;
            border-bottom: 1px solid #DFDFDF;
        }

        .question_list {
            padding: 30px;
            padding-top: 0;

            .question_item {
                margin-top: 25px;

                .q_ask {
                    display: flex;
                    align-items: baseline;

                    >div:nth-child(1) {
                        width: 20px;
                        height: 20px;
                        background: #FECD61;
                        border-radius: 3px 3px 3px 3px;
                        text-align: center;
                        line-height: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        flex-shrink: 0;

                    }

                    >div:nth-child(2) {
                        padding: 5px 20px;
                        background: #FECD61;
                        border-radius: 15px 15px 15px 15px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        margin-left: 10px;
                    }
                }

                .q_answer {
                    display: flex;
                    align-items: baseline;
                    margin-top: 12px;

                    >div:nth-child(1) {
                        width: 20px;
                        height: 20px;
                        background: #E3E3E3;
                        border-radius: 3px 3px 3px 3px;
                        text-align: center;
                        line-height: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        flex-shrink: 0;
                    }

                    >div:nth-child(2) {
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #555555;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>